/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const PageWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
`;

export { PageWrapper };
